import * as CookieConsent from "vanilla-cookieconsent";

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({
  // root: 'body',
  // autoShow: true,
  // disablePageInteraction: true,
  // hideFromBots: true,
  // mode: 'opt-in',
  // revision: 0,

  cookie: {
    name: "cc_cookie",
    // domain: location.hostname,
    // path: '/',
    // sameSite: "Lax",
    // expiresAfterDays: 365,
  },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: "cloud inline",
      position: "bottom center",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  onFirstConsent: ({ cookie }) => {
    console.log("onFirstConsent fired", cookie);
  },

  onConsent: ({ cookie }) => {
    console.log("onConsent fired!", cookie);
  },

  onChange: ({ changedCategories, changedServices }) => {
    console.log("onChange fired!", changedCategories, changedServices);
  },

  onModalReady: ({ modalName }) => {
    console.log("ready:", modalName);
  },

  onModalShow: ({ modalName }) => {
    console.log("visible:", modalName);
  },

  onModalHide: ({ modalName }) => {
    console.log("hidden:", modalName);
  },

  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
      services: {}
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^_ga/, // regex: match all cookies starting with '_ga'
          },
          {
            name: "_gid", // string: exact cookie name
          },
        ],
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
      services: {
        ga: {
          label: "Google Analytics",
          onAccept: () => {},
          onReject: () => {},
        },
        facebook: {
          label: "Facebook",
          onAccept: () => {},
          onReject: () => {},
        },
        linkedIn: {
          label: "LinkedIn",
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
    ads: {
      services: {
        tt: {
          label: "TikTok",
          onAccept: () => {},
          onReject: () => {},
        },
        twitter: {
          label: "X/Twitter",
          onAccept: () => {},
          onReject: () => {},
        },
      },
    },
  },

  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "We use cookies",
          description:
            "Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          showPreferencesBtn: "Manage Individual preferences",
          // closeIconLabel: 'Reject all and close modal',
          footer: `
                        Read our&nbsp;<a href="#path-to-privacy-policy.html" target="_blank">privacy and cookie policy</a>
                    `,
        },
        preferencesModal: {
          title: "Manage cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Accept current selection",
          closeIconLabel: "Close modal",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              title: "Your Privacy Choices",
              description: `In this panel you can express some preferences related to the processing of your personal information. You may review and change expressed choices at any time by resurfacing this panel via the provided link. To deny your consent to the specific processing activities described below, switch the toggles to off or use the “Reject all” button and confirm you want to save your choices.`,
            },
            {
              title: "Strictly Necessary",
              description:
                "These cookies are essential for the proper functioning of the website and cannot be disabled.",

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: "necessary",
              cookieTable: {
                caption: "Cookie table",
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                  expiration: "Expiration",
                },
                body: [
                  {
                    name: "CraftSessionId",
                    domain: location.hostname,
                    desc: "Allows the website to store data for the duration of the session",
                    expiration: "Session",
                  },
                  {
                    name: "CRAFT_CSRF_TOKEN",
                    domain: location.hostname,
                    desc: "Protects us and you as a user against Cross-Site Request Forgery attacks.",
                    expiration: "Session",
                  },
                  {
                    name: "cc_cookie",
                    domain: location.hostname,
                    desc: "Stores the user's cookie consent state for the current domain",
                    expiration: "6 months",
                  },
                ],
              },
            },
            {
              title: "Performance and Analytics",
              description:
                "These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.",
              linkedCategory: "analytics",
              cookieTable: {
                caption: "Cookie table",
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                },
                body: [
                  {
                    name: "_ga",
                    domain: location.hostname,
                    desc: "Description 1",
                  },
                  {
                    name: "_gid",
                    domain: location.hostname,
                    desc: "Description 2",
                  },
                ],
              },
            },
            {
              title: "Targeting and Advertising",
              description:
                "These cookies are used to make advertising messages more relevant to you and your interests. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
              linkedCategory: "ads",
              cookieTable: {
                caption: "Cookie table",
                headers: {
                  name: "Cookie",
                  domain: "Domain",
                  desc: "Description",
                },
                body: [
                  // {
                  //   name: "test",
                  //   domain: location.hostname,
                  //   desc: "Description 1",
                  // },
                  // {
                  //   name: "test 2",
                  //   domain: location.hostname,
                  //   desc: "Description 2",
                  // },
                ],
              },
            },
            {
              title: "More information",
              description:
                'For any queries in relation to our policy on cookies and your choices, please <a href="/contact-us">contact us</a>',
            },
          ],
        },
      },
    },
  },
});
